'use client';

import * as React from 'react';
import {
    getDefaultWallets,
    RainbowKitProvider,
    connectorsForWallets,
    darkTheme,
    lightTheme,
    DisclaimerComponent
} from '@rainbow-me/rainbowkit';
import {
    okxWallet,
    rabbyWallet,
    trustWallet,
    braveWallet,
    metaMaskWallet,
    bifrostWallet,
    coinbaseWallet,
    walletConnectWallet,
    injectedWallet
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { Chain, songbird } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from "wagmi/providers/alchemy";
import {ToastContainer} from "react-toastify";

const projectId = "dfcf429f3d585732eb42a010b9848027"
const alchemyId = "cC4O9amYVqevBbAb-h2i0Mg1ayQda01b"

export const flare = {
    id: 14,
    name: 'Flare',
    network: 'flare-mainnet',
    nativeCurrency: {
        decimals: 18,
        name: 'flare',
        symbol: 'FLR',
    },
    rpcUrls: {
        public: { http: ['https://flarerpc.ftsolottery.xyz/ext/C/rpc'] },
        default: { http: ['https://flarerpc.ftsolottery.xyz/ext/C/rpc'] },
    },
    blockExplorers: {
        default: { name: 'Flare Explorer', url: 'https://flare-explorer.flare.network' },
    },
} as const satisfies Chain

const { chains, publicClient, webSocketPublicClient } = configureChains(
    [
        flare, songbird
    ],
    [
        alchemyProvider({ apiKey: alchemyId }),
        publicProvider()]
);

const { wallets } = getDefaultWallets({
    appName: 'FTSOLottery',
    projectId,
    chains,
});

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
    <Text>
        By connecting your wallet to this app, you agree to the app s
        <Link href="https://ftsolottery.xyz/terms-condition/">Terms of Service</Link> and
        <Link href="https://ftsolottery.xyz/privacy-policy/">Privacy Policy</Link>
    </Text>
);

const AppInfo = {
    appName: 'FTSOLottery',
    disclaimer: Disclaimer
};

const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [
            injectedWallet({ chains }),
            metaMaskWallet({ chains, projectId }),
            bifrostWallet({ chains, projectId }),
            coinbaseWallet({ appName: 'FTSOLottery', chains }),
            walletConnectWallet({ chains, projectId }),
        ],
    },
    {
        groupName: 'Others',
        wallets: [
            trustWallet({ chains, projectId }),
            braveWallet({ chains }),
            okxWallet({ chains, projectId }),
            rabbyWallet({ chains }),
        ],
    },
]);

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
});

export function Providers({ children }: { children: React.ReactNode }) {
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
        setMounted(true);
    }, []);
    return (
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains} appInfo={AppInfo}
                                theme={{
                                    lightMode: lightTheme({
                                        accentColor: '#f67a3c',
                                        accentColorForeground: 'white',
                                        borderRadius: 'small',
                                        fontStack: 'system',
                                        overlayBlur: 'small',
                                    }),
                                    darkMode: darkTheme({
                                        accentColor: '#f67a3c',
                                        accentColorForeground: 'white',
                                        borderRadius: 'small',
                                        fontStack: 'system',
                                        overlayBlur: 'small',
                                    }),
                                }}>
                {mounted && children}
            </RainbowKitProvider>
            <ToastContainer />
        </WagmiConfig>

    );
}
