import { Roboto } from 'next/font/google';
import { createTheme } from '@mui/material/styles';

const roboto = Roboto({
    weight: ['300', '400', '500', '700'],
    subsets: ['latin'],
    display: 'swap',
});

const theme = createTheme({
    palette: {
        mode: 'light',
        warning: {
            light: 'rgba(210,98,19,1)',
            main: 'rgba(180, 83, 9, 1)',
            dark: 'rgba(200, 93, 19, 1)',
            contrastText: '#fff',
        },
        primary: {
            light: 'rgba(210,98,19,1)',
            main: 'rgba(180, 83, 9, 1)',
            dark: 'rgba(200, 93, 19, 1)',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: roboto.style.fontFamily,
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.severity === 'info' && {
                        backgroundColor: 'rgb(229, 246, 253)',
                    }),
                }),
            },
        },
    },
});

export default theme;